import React, { useState, useEffect } from 'react'

import TabImg2 from '../../assets/images/chick_1.png'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { useAccount, useContractReads } from 'wagmi'
import { ethers } from 'ethers'

import { MerkleMintDataProps, TFGSalesDataProps } from '../../types'

import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import ButtonContractWrite from '../ButtonContractWrite'
import GasLimitNote from '../GasLimitNote'

import MintImage from '../../assets/images/minting_animations/presale_live.gif'
import MintingCharacter from '../../assets/images/minting_animations/new_character_mint.gif'
import MintCommitImage from '../../assets/images/minting_animations/mint_commit.gif'
import { useContractABIs } from '../../hooks/useContractsABI'
import { useContracts } from '../../hooks/useContracts'

interface PropTypes {
  TFGSalesData: TFGSalesDataProps
}

const getValueInWei = (num: number): ethers.BigNumber => {
  const [base, exponent] = String(num.toExponential()).split('e')
  return ethers.utils.parseUnits(base, +exponent + 18)
}

const MintPreSale = (props: PropTypes): JSX.Element => {
  const { TFGSalesData } = props

  const ContractAddresses = useContracts()
  const ContractABIs = useContractABIs()

  const FarmGameMintContractAddress = ContractAddresses.TheFarmGameMintContractAddress

  const { address, isConnected } = useAccount()

  const [pauseWatch, setPauseWatch] = useState(false)
  const [hasNoPendingMints, setHasNoPendingMints] = useState(false)
  const [canReveal, setCanReveal] = useState(false)

  const handlePauseWatch = () => {
    setPauseWatch(true)
  }

  const handleMintSuccess = (data: any) => {
    setPauseWatch(false)
  }
  const handleMintStakeSuccess = (data: any) => {
    setPauseWatch(false)
  }

  const handleRevealSuccess = (data: any) => {
    setPauseWatch(false)
  }

  const [mintQty, setMintQty] = useState(1)
  const [maxQty, setMaxQty] = useState(0)

  // PreSale
  const [isMerkleWhiteListed, setIsMerkleWhiteListed] = useState(false)
  const [merkleInfo, setMerkleInfo] = useState<MerkleMintDataProps>()
  const [verifiedMerkle, setVerifiedMerkle] = useState(false)

  const [mintPreSaleTotal, setMintPreSaleTotal] = useState(TFGSalesData?.preSaleFee ? TFGSalesData.preSaleFee : 0)

  const [mintPreSaleStakeTotal, setMintPreSaleStakeTotal] = useState(
    TFGSalesData?.preSaleStakeFee ? TFGSalesData.preSaleStakeFee : 0,
  )

  const [userUnstakedBalance, setUserUnStakedBalance] = useState(0)
  const [userStakedBalance, setUserStakedBalance] = useState(0)

  // FarmAnimals Balance check
  const farmAnimalsConfig = {
    address: ContractAddresses.FarmAnimalsContractAddress,
    abi: ContractABIs.FarmAnimalsABI,
  }
  const henHouseConfig = {
    address: ContractAddresses.HenHouseContractAddress,
    abi: ContractABIs.HenHouseABI,
  }

  const theFarmGameMint = {
    address: ContractAddresses.TheFarmGameMintContractAddress,
    abi: ContractABIs.TheFarmGameMintABI,
  }

  const theFarmAnimalMintConfig = {
    address: ContractAddresses.TheFarmGameMintContractAddress,
    abi: ContractABIs.TheFarmGameMintABI,
  }

  useContractReads({
    contracts: [
      { ...farmAnimalsConfig, functionName: 'balanceOf', args: [address] },
      { ...henHouseConfig, functionName: 'getStakedByAddress', args: [address?.toLowerCase()] },
      { ...farmAnimalsConfig, functionName: 'minted' },
      { ...theFarmGameMint, functionName: 'getPreSaleMintRecord', args: [address] },
      { ...theFarmAnimalMintConfig, functionName: 'hasMintPending', args: [address?.toLowerCase()] },
      { ...theFarmAnimalMintConfig, functionName: 'canReveal', args: [address?.toLowerCase()] },
    ],
    watch: true,
    onSuccess(data) {
      if (data) {
        // Unstaked
        const unStakedBalance = parseInt(data[0].toString())
        setUserUnStakedBalance(unStakedBalance)

        // Staked
        const stakedBalance = data[1].length
        setUserStakedBalance(stakedBalance)
        // setPreSaleMinted(parseInt(data[3].toString()))
        // Fees

        if (mintQty === 1) {
          setMintPreSaleTotal(TFGSalesData.preSaleFee)
        }

        setMintPreSaleStakeTotal(TFGSalesData.preSaleStakeFee * mintQty)

        const totalRemainingForUser = TFGSalesData.preSaleMaxQty - parseInt(data[3].toString())

        const totalReamingGen0 = TFGSalesData.maxGen0Supply - TFGSalesData.totalSupply

        if (totalRemainingForUser < totalReamingGen0) {
          setMaxQty(totalRemainingForUser)
        } else {
          setMaxQty(totalReamingGen0)
        }
        setHasNoPendingMints(!data[4])

        const canRevealResult = data[5] as unknown as boolean
        setCanReveal(canRevealResult)
      }
    },
  })

  const updateMintAmount = (status: boolean) => {
    if (TFGSalesData) {
      if (status && mintQty) {
        setMintQty(mintQty + 1)
        setMintPreSaleTotal((mintQty + 1) * TFGSalesData.preSaleFee)
        setMintPreSaleStakeTotal((mintQty + 1) * TFGSalesData.preSaleStakeFee)
      } else if (!status && mintQty > 1) {
        setMintQty(mintQty - 1)
        setMintPreSaleTotal((mintQty - 1) * TFGSalesData.preSaleFee)
        setMintPreSaleStakeTotal((mintQty - 1) * TFGSalesData.preSaleStakeFee)
      }
    }
  }

  const getMerkleData = () => {
    const fetchData = async () => {
      // get the data from the api
      const { data: merkleData } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/merkle/allow_list/proof/${address}`,
      )
      if (merkleData && merkleData.proof) {
        setIsMerkleWhiteListed(true)
        setVerifiedMerkle(true)
        setMerkleInfo({
          merkleProof: merkleData.proof,
        })
      } else {
        setIsMerkleWhiteListed(false)
        setVerifiedMerkle(true)
      }
    }
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error)
  }

  useEffect(() => {
    // declare the async data fetching function

    // if (TFGSalesData && TFGSalesData.saleStatus === 'presale') {
    getMerkleData()
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePreSaleMintSuccess = async (data: any) => {
    getMerkleData()
  }

  if (!TFGSalesData) {
    return (
      <div className='loading--section'>
        <h1>Minting Loading...</h1>
      </div>
    )
  }

  return (
    <div className='col-xl-5 col-lg-6'>
      <div className='tab__card'>
        <div className='tab__text'>
          {/* <h1>MINTING {TFGSalesData?.saleStatus}</h1> */}

          <img src={MintImage} width={384} height={384} alt='PreSale Live' />
          <br />
          <br />
          <p>
            Gen 0 Supply:{' '}
            {TFGSalesData.totalSupply > TFGSalesData.maxGen0Supply
              ? TFGSalesData.maxGen0Supply
              : TFGSalesData.totalSupply}
            /{TFGSalesData.maxGen0Supply}
          </p>

          {(userUnstakedBalance > 0 || userStakedBalance > 0) && (
            <p>You own {userUnstakedBalance + userStakedBalance} animals</p>
          )}
        </div>
        {isConnected ? (
          <>
            {TFGSalesData.totalSupply + TFGSalesData.pendingMintQty >= TFGSalesData.maxGen0Supply ? (
              <>
                <h1>Sold out</h1>
              </>
            ) : (
              <>
                {verifiedMerkle ? (
                  <>
                    {TFGSalesData.saleStatus === 'presale' && isMerkleWhiteListed && merkleInfo ? (
                      <>
                        {maxQty > 0 && hasNoPendingMints ? (
                          <>
                            <h2>Max QTY: {TFGSalesData.preSaleMaxQty}</h2>
                            <h2>Remaining QTY: {maxQty}</h2>
                            <div className='in__zoom'>
                              <div className='in__content'>
                                <button className='btn-circle' onClick={() => updateMintAmount(false)}>
                                  <FontAwesomeIcon className='btn_qty brown' icon={faMinus} />
                                </button>
                                <div className='in__zoom__first'>
                                  <img src={TabImg2} alt='' />
                                </div>
                                <button
                                  className='btn-circle'
                                  disabled={mintQty === maxQty}
                                  onClick={() => updateMintAmount(true)}
                                >
                                  <FontAwesomeIcon className='brown' icon={faPlus} />
                                </button>
                              </div>
                              <h2>
                                Mint <span className='brown'>{mintQty}</span> for {mintPreSaleTotal.toFixed(3)} ETH
                              </h2>
                              <br />
                              <h2>
                                Mint & Stake <span className='brown'>{mintQty}</span> for{' '}
                                {mintPreSaleStakeTotal.toFixed(3)} ETH
                              </h2>
                            </div>
                            <div className='btn__card'>
                              <ButtonContractWrite
                                ContractAddress={FarmGameMintContractAddress}
                                ContractABI={ContractABIs.TheFarmGameMintABI}
                                ContractFunction='preSaleMint'
                                ContractArgs={[mintQty, false, merkleInfo.merkleProof]}
                                ContractOverRides={{
                                  value: getValueInWei(mintPreSaleTotal),
                                }}
                                ContractIsEnabled={mintPreSaleTotal > 0 && hasNoPendingMints}
                                ButtonTextPrepareIdle={`Check for Hens`}
                                ButtonTextPrepareLoading={`Preparing`}
                                ButtonTextInitial={`Mint Commit`}
                                ButtonTextLoading={'Waiting for approval'}
                                ButtonTextWriting={'Hatching hens...'}
                                ButtonTextError={"Couldn't Hatch any Hens"}
                                StartAlertText={'Incubating Hen Eggs!'}
                                StartAlertOptions={{ icon: '🥚' }}
                                SuccessAlertText={'Hatched a Hen!'}
                                SuccessAlertOptions={{ icon: '🥚' }}
                                OnSuccessFunction={handlePreSaleMintSuccess}
                                imageWaitTx={MintCommitImage}
                              />
                              <ButtonContractWrite
                                ContractAddress={FarmGameMintContractAddress}
                                ContractABI={ContractABIs.TheFarmGameMintABI}
                                ContractFunction='preSaleMint'
                                ContractArgs={[mintQty, true, merkleInfo.merkleProof]}
                                ContractOverRides={{
                                  value: getValueInWei(mintPreSaleStakeTotal),
                                }}
                                ContractIsEnabled={mintPreSaleStakeTotal > 0 && hasNoPendingMints}
                                ButtonTextPrepareIdle={`Check for Hens`}
                                ButtonTextPrepareLoading={`Preparing`}
                                ButtonTextInitial={`Mint & Stake Commit`}
                                ButtonTextLoading={'Waiting for approval'}
                                ButtonTextWriting={'Hatching hens...'}
                                ButtonTextError={"Couldn't Hatch any Hens"}
                                StartAlertText={'Incubating Hen Eggs!'}
                                StartAlertOptions={{ icon: '🥚' }}
                                SuccessAlertText={'Moved into Hen House!'}
                                SuccessAlertOptions={{ icon: '🥚' }}
                                OnSuccessFunction={handlePreSaleMintSuccess}
                                imageWaitTx={MintCommitImage}
                              />
                            </div>
                            <GasLimitNote />
                          </>
                        ) : (
                          <>{hasNoPendingMints && <h1>Address minted presale limit</h1>}</>
                        )}
                      </>
                    ) : (
                      <h2>Address not whitelisted</h2>
                    )}
                  </>
                ) : (
                  <h2>Checking eligibility</h2>
                )}
              </>
            )}
          </>
        ) : (
          <div className='btn__box'>
            <div className='btn__card btn__anchor'>
              <ConnectButton chainStatus='none' accountStatus='avatar' showBalance={false} />
            </div>
          </div>
        )}
        {isConnected && !hasNoPendingMints && (
          <div className='btn__box'>
            <h1>You have pending mints</h1>
            <h1>Reveal opens every ~15mins</h1>
            <div className='btn__card' onClick={handlePauseWatch}>
              <ButtonContractWrite
                ContractAddress={FarmGameMintContractAddress}
                ContractABI={ContractABIs.TheFarmGameMintABI}
                ContractFunction='mintReveal'
                ContractArgs={[]}
                ContractOverRides={{}}
                ContractIsEnabled={canReveal && !hasNoPendingMints}
                ButtonTextPrepareIdle={`No peeking`}
                ButtonTextPrepareLoading={`Preparing`}
                ButtonTextInitial={`Reveal Animals`}
                ButtonTextLoading={'Waiting for approval'}
                ButtonTextWriting={'Hatching hens...'}
                ButtonTextError={"Couldn't Hatch any Hens"}
                StartAlertText={'Incubating Hen Eggs!'}
                StartAlertOptions={{ icon: '🥚' }}
                SuccessAlertText={'Hatched a Hen!'}
                SuccessAlertOptions={{ icon: '🥚' }}
                OnSuccessFunction={handleRevealSuccess}
                imageWaitTx={MintingCharacter}
              />
            </div>
            <GasLimitNote />
          </div>
        )}
      </div>
    </div>
  )
}

export default MintPreSale
